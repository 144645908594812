import { Outlet, Link } from "react-router-dom";

const ISP = () => {
    
    return (
        <>
            <div id="sidebar" className="m-2">
                <nav className="m-2">
                    <ul className ="nav nav-tabs">
                        <li className="nav-item">
                            <Link className = "nav-link font-weight-bold" to={`viewOperatorList`}>All Operators</Link>
                        </li>
                        <li className="nav-item">
                            <Link className = "nav-link font-weight-bold" to={`addMobileOperator`}>Add Operator</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className = "nav-link font-weight-bold" to={`mobileOperatorDetail`}>Operator Detail</Link>
                        </li>*/}
                        <li className="nav-item">
                            <Link className = "nav-link font-weight-bold" to={`editOperator`}>Edit Operator</Link>
                        </li> 
                    </ul>
                </nav>      
            </div>

            <div className="m-2">
                <Outlet />
            </div>
        </>
    )

}

export default ISP