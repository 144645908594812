import { useState, useContext } from "react"
import { setUserContext } from "../userContext";
import {loginUser} from "../API/login"


export default function LoginScreen() {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const {setUserDetails} = useContext(setUserContext)

    const onUserNameChange =(e) => {
        e.preventDefault();
        setUsername(e.target.value)
    }

    const onPasswordChange =(e) => {
        e.preventDefault();
        setPassword(e.target.value)
    }

    const handleLoginUser = async (e) =>{
        e.preventDefault();
        var response = await loginUser({"username":username, "password":password})
        if (response.status){
            const response_data = {
                userName: username,
                token: response.token,
                isLoggedIn: true
            }

            sessionStorage.setItem("token", response.token)
            sessionStorage.setItem("username", username)
            sessionStorage.setItem("isLoggedIn", true)

            setUserDetails(response_data)
        }
    }

    return (
        <div>
            <br></br>
            <br></br>
            <h3><b>Login Screen</b></h3>
            <br></br>
            <div>
            
            <input style={{width:"300px"}} type = "text" name = "username" onChange={e=>onUserNameChange(e)} value={username} placeholder="User Name"/>
            <br></br>
            
            <input style={{width:"300px", marginTop:"10px"}} type = "password" name = "password" onChange={e=>onPasswordChange(e)} value={password} placeholder="Password"/>
            <br></br>
            <button className = "mt-2 btn btn-primary"onClick = {e=>handleLoginUser(e)}>Submit</button>
            </div>
        </div>
    )
}