import { useContext } from "react"
import {RiBankCard2Fill, RiUser6Fill, RiMastercardFill, RiLogoutCircleLine} from "react-icons/ri"
import {MdOutlineDeviceHub,MdPrecisionManufacturing, MdNetworkWifi} from "react-icons/md"
import { Link } from "react-router-dom";
import { setUserContext } from "../../userContext";

const Navigation = ({toggleState}) => {

    const {logOutUser} = useContext(setUserContext)

    const navigation_panel = [{baseRoute:"reports", default:"", label:"Reports", icon:<RiBankCard2Fill size="2rem" color="white"/>},
                              {baseRoute:"esimInventory", default:"inventoryList", label:"Inventory", icon:<MdOutlineDeviceHub size="2rem" color="white"/>},
                              {baseRoute:"simPlansMaster", default:"viewSIMList", label:"SIM Plans", icon:<RiMastercardFill size="2rem" color="white"/>},
                              {baseRoute:"manufacturers", default:"viewManufacturerList", label:"Manufacturers", icon:<MdPrecisionManufacturing size="2rem" color="white"/>},
                              {baseRoute:"mobileOperator", default:"viewOperatorList", label:"Operator", icon:<MdNetworkWifi size="2rem" color="white"/>},
                              {baseRoute:"user", default:"userList", label:"Users", icon:<RiUser6Fill size="2rem" color="white"/>},  
                            ]

    const navigation_tab = (data) => {
                            return  <li className="nav-item d-flex align-items-center p-1 mb-2">            
                                        <Link to={data.baseRoute+"/"+data.default} className="d-flex align-items-center">{data.icon}
                                          {!toggleState ? <span className="ml-1 text-white align-middle">{data.label}</span>:null}
                                        </Link>
                                    </li>
                            }
                   
    const onLogOut = () => {
      logOutUser()
    }

    return (
            <nav>
              <ul className="nav flex-column pt-3">
                {navigation_panel.map((item)=>{return navigation_tab(item)})}
                <li className="nav-item d-flex align-items-center p-1 mb-2" onClick={e=>onLogOut()}>            
                    <span className="d-flex align-items-center"><RiLogoutCircleLine size="2rem" color="white"/>
                      {!toggleState ? <span className="ml-1 text-white align-middle">Logout</span>:null}
                    </span>
                </li>
              </ul>
            </nav>      
    )
}

export default Navigation