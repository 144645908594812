import {useState} from "react"
import {addUser, getUserList} from "../../API/user"
import LoadingWidget from "../components/loading"
import {useNavigate} from "react-router-dom";

export default function CreateUser() {

    const navigate = useNavigate()

    const [data, setData] = useState({})
    const [userError, setuserError] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [errorArray, setErrorArray] = useState([])

    const onChange = (e) => {
        const data_new = data
        data_new[e.target.name] = e.target.value
        setData(data_new)
    }    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const user_list = await getUserList()
        let isUserIdTaken = []

        if(user_list.status){
            isUserIdTaken = user_list["userlist"].filter(item=>item.username === data.username)
        }
         
        if (isUserIdTaken.length > 0){
            setuserError("User ID already taken")
        } else {
            const data_new = data
            data_new["createdDt"] =   new Date()
            var response = await addUser(data_new)
            if (!response["status"]){
                setErrorArray(errorArray.concat(response["message"]))
            } else {
                setIsLoading(false)
                navigate('/user/userList')        
            }
        }
        setIsLoading(false)
    }

    return (
        <div>
           <br></br>
            <p><b>Create User</b></p>
            {!isLoading ? 
            <form className = "m-2" onSubmit={e=>handleSubmit(e)}>
            <div className="row m-1">
                <label className="col-4">User Name</label>
                <input className="col-4 form-control" name="username" type="text" onChange={e=>onChange(e)}></input>
            </div> 
            <div className="row m-1">
                <label className="col-4">Password</label>
                <input className="col-4 form-control" name="password" type="text" onChange={e=>onChange(e)}></input>
            </div>
            <div className="row m-1">
                <label className="col-4">Role</label>
                <select className="col-4 form-control" name="role" value = {data["role"]} onChange = {e=>onChange(e)}>
                    <option value = "">Select</option>
                    <option value = "SuperUser">Internal</option>
                    <option value = "ExternalUser">External</option>
                </select>
            </div>
            
            <div>
                <input className="btn btn-primary" value="submit" type="submit"></input>
            </div>
        </form> : <LoadingWidget/>}
             
                
        </div>
    )
}