import {useContext} from "react"
import { SIMPlansContext } from "../../simPlansContext"
import DynamicTable from "../components/Table"

const ViewSIMPlanList = () => {

    const {SIMPlans} = useContext(SIMPlansContext);

    return (
        <>
        {SIMPlans && SIMPlans.length > 0 ? <DynamicTable data = {SIMPlans} sequence = {["planTag", "planDescription", "planValidityMonth", "dataLimitMB", "smsLimitCount"]} /> : <p>No SIM Plans created on the platform Yet!!</p>}
    </>

    )
}

export default ViewSIMPlanList