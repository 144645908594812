import React, {createContext, useState, useEffect} from 'react';
const UserContext = createContext(undefined);
const setUserContext = createContext(undefined)

function UserProvider({ children }) {
  const [userDetails, setUserDetails] = useState({userName: "", token: "", isLoggedIn: false});

  useEffect(()=>{
    if (sessionStorage.getItem("token")!==null){
      setUserDetails({
        userName: sessionStorage.getItem("username"),
        token: sessionStorage.getItem("token"),
        isLoggedIn: sessionStorage.getItem("isLoggedIn")
      })
    } else {
      setUserDetails({
        userName: "",
        token: "",
        isLoggedIn: false
      })
    }
  },[])

  let logOutUser = () => {
    setUserDetails({
      userName: "",
      token: "",
      isLoggedIn: false})
    
    sessionStorage.removeItem("token")
    sessionStorage.removeItem("username")
    sessionStorage.removeItem("isLoggedIn")  
  }

  return (
    <UserContext.Provider value={userDetails}>
        <setUserContext.Provider value={{setUserDetails, logOutUser}}>
            {children}
        </setUserContext.Provider>
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext, setUserContext };
