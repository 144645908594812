import {useState, useContext} from 'react'
import {addManufacturer, getManufacturerList} from "../../API/maufacturer"
import { ManufacturerContext } from "../../manufacturerContext"
import LoadingWidget from "../components/loading"
import {useNavigate} from "react-router-dom";

const AddManufacturer = () => {

    const navigate = useNavigate()
    
    const {updateManufacturer} = useContext(ManufacturerContext);
    
    const [data, setData] = useState({})
    const [manufacturerCodeError, setManufacturerCodeError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    
    const onChange = (e) => {
        const data_new = data
        data_new[e.target.name] = e.target.value
        setData(data_new)
    }
    
    const fileUpload = (e) => {
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const manufacturer_list = await getManufacturerList()
        let isManufacturerIDTaken = []

        if(manufacturer_list.status){
            isManufacturerIDTaken = manufacturer_list["manufacturer"].filter(item=>item.code === data.code)
        }
         
        if (isManufacturerIDTaken.length > 0){
            setManufacturerCodeError("Manufacturer Code already taken!!")
            setIsLoading(false)
        } else {    
            var data_upload = data
            data_upload["createdBy"] = "admin"
            data_upload["kycDocument"] = "s3bucket2"
            data_upload["otherDocuments"] = "s3bucket3"
            
            var response = await addManufacturer(data_upload)
            updateManufacturer()
            setIsLoading(false)
            navigate('/manufacturers/viewManufacturerList')   
        }
    }
    
    return (
        <>
            {manufacturerCodeError ? <p className="alert alert-danger">{manufacturerCodeError}</p> : null}
            { !isLoading ? <form className = "m-2" onSubmit={e=>handleSubmit(e)}>
                <div className="row m-1">
                    <label className="col-4">Manufacturer ID</label>
                    <input className="col-4 form-control" name="code" type="text" onChange={e=>onChange(e)}></input>
                </div> 
                <div className="row m-1">
                    <label className="col-4">Manufacturer Name</label>
                    <input className="col-4 form-control" name="name" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">Manufacturer GST</label>
                    <input className="col-4 form-control" name="gst" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <p><b>Manufacturer Address</b></p>
                <div className="row m-1"> 
                    <label className="col-4">Address</label>
                    <input className="col-4 form-control" name="address" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">City</label>
                    <input className="col-4 form-control" name="city" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">State</label>
                    <input className="col-4 form-control" name="state" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">Pin Code</label>
                    <input className="col-4 form-control" name="pin" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <br></br>
                <p><b>POC Details</b></p>
                <div className="row m-1">
                    <label className="col-4">Name</label>
                    <input className="col-4 form-control" name="pocName" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">Email</label>
                    <input className="col-4 form-control" name="email" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">Contact</label>
                    <input className="col-4 form-control" name="contact" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <br></br>
                <p><b>KYC Documents</b></p>
                <div className="row m-1">
                    <label className="col-4">Registration Form</label>
                    <input className="col-4 form-control" name="otherDocuments" type="file" onChange={e=>fileUpload(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">Identity Proof</label>
                    <input className="col-4 form-control" name="identityProof" type="file" onChange={e=>fileUpload(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">Address Proof</label>
                    <input className="col-4 form-control" name="addressProof" type="file" onChange={e=>fileUpload(e)}></input>
                </div>

                <div>
                    <input className="btn btn-primary" value="submit" type="submit"></input>
                </div>
            </form> : <LoadingWidget />}
        </>
    )

}

export default AddManufacturer