const url = "https://iotsim.co.in/"

export async function loginUser(data){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(data);    
    console.log("raw", raw)

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };

    let response = await fetch(url+"api/Login/userlogin", requestOptions)
    console.log("response_status", response)
    return response.json()
}
