const url = "https://iotsim.co.in/"

export async function addAPIDocumentStorage(file){
    console.log(file[0])
    let token = sessionStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    myHeaders.append("Authorization", "Bearer " + token);
    
    var formdata = new FormData();
    formdata.append("file", file[0]);

    console.log("formdata",formdata)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
    try {
        const response = await fetch(url+"api/Plans/UploadDoc", requestOptions)
        const data_response = await response.json()
        console.log(data_response)
        return data_response
    } catch (e) {
        return e
    }    
}



