import React, {createContext, useState, useEffect, useContext} from 'react';
import {getISPList} from "./API/isp"
import { UserContext } from './userContext';

const ISPContext = createContext(undefined);

function ISPProvider({ children }) {

  const userDetails = useContext(UserContext);

  const [ISP, setISP] = useState(null);

  useEffect(()=>{
    const getISPs = async () => {
        const response_api = await getISPList()
        if(response_api.status){
            setISP(response_api["ispmaster"])
        }
    }  
    
    if (sessionStorage.getItem("token")!==null){
        getISPs()    
    } 
  },[userDetails,])

  let updateISP = async () => {
    const response_api = await getISPList()
        if(response_api.status){
          setISP(response_api["ispmaster"])
        }
  }


  return (
    <ISPContext.Provider value={{ISP, updateISP}}>
            {children}
    </ISPContext.Provider>
  );
}

export {ISPProvider, ISPContext};
