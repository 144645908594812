import React, {createContext, useState, useEffect, useContext} from 'react';
import {getPlansList} from "./API/plans"
import { UserContext } from './userContext';

const SIMPlansContext = createContext(undefined);

function SIMPlanProvider({ children }) {

  const userDetails = useContext(UserContext);

  const [SIMPlans, setSIMPlans] = useState(null);

  useEffect(()=>{
    const getSIMPlans = async () => {
        const response_api = await getPlansList()
        if(response_api.status){
            setSIMPlans(response_api["inventory"])
        }
    }  
    
    if (sessionStorage.getItem("token")!==null){
        getSIMPlans()    
        
    } 
  },[userDetails,])

  let updateSIMPlans = async () => {
    const response_api = await getPlansList()
        if(response_api.status){
            setSIMPlans(response_api["inventory"])
        }
  }


  return (
    <SIMPlansContext.Provider value={{SIMPlans, updateSIMPlans}}>
            {children}
    </SIMPlansContext.Provider>
  );
}

export {SIMPlanProvider, SIMPlansContext};
