import React, {createContext, useState, useEffect, useContext} from 'react';
import {getESIMInventory} from "./API/inventory"
import { UserContext } from './userContext';

const ESIMInventoryContext = createContext(undefined);

function ESIMInventoryProvider({ children }) {

  const userDetails = useContext(UserContext);

  const [esimInventory, setEsimInventory] = useState(null)
    
  useEffect(()=>{

      const ESIMInventory = async () => {
          const response_api = await getESIMInventory()
          if(response_api.status){
              setEsimInventory(response_api["inventory"])
          }
      }
      if (sessionStorage.getItem("token")!==null){
        ESIMInventory()
    }
      
  },[userDetails])


  let updateInventory = async () => {
    const response_api = await getESIMInventory()
        if(response_api.status){
            setEsimInventory(response_api["inventory"])
        }
  }

  return (
    <ESIMInventoryContext.Provider value={{esimInventory, updateInventory}}>
            {children}
    </ESIMInventoryContext.Provider>
  );
}

export {ESIMInventoryProvider, ESIMInventoryContext};
