import {useState, useContext} from 'react'
import LoadingWidget from "../components/loading"
import {useNavigate} from "react-router-dom";
import { ISPContext } from "../../ispContext";
import {SIMPlansContext} from "../../simPlansContext"
import {addESIMPlan, getPlansList} from "../../API/plans";

const AddSIMPlan = () => {

    const navigate = useNavigate()
    const {ISP} = useContext(ISPContext);
    const {SIMPlans, updateSIMPlans} = useContext(SIMPlansContext);

    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [errorArray, setErrorArray] = useState([])

    const onChange = (e) => {
        const data_new = data
        data_new[e.target.name] = e.target.value
        setData(data_new)
    }
    
    const handleSubmit = async (e) => {
            e.preventDefault()
            setIsLoading(true)
            let ispProvider = data && "ispId" in data ? ISP.filter(item=>item["id"] === data["ispId"])[0]["providerCode"] : null
            let planTag = ispProvider+"_"+data["planDescription"]+"_"+data["planValidityMonth"]+"M"+"_"+data["dataLimitMB"]+"MB"+"_"+data["smsLimitCount"]+"SMS"
            console.log("SIMPlans",SIMPlans)
            
            var isPlanInList = SIMPlans ? SIMPlans.filter(item=> item["planTag"]===planTag) : []
            
            if(isPlanInList.length === 0){
                const data_final = data
                data_final["planTag"] = planTag
                var response = await addESIMPlan(data)
                updateSIMPlans()
                setIsLoading(false)
                navigate('/simPlansMaster/viewSIMList')       
            } else {
                setIsLoading(false)
                setErrorArray(errorArray.concat(["Existing Plan Exists"]))
            }   
        }
    
        
    return (
        <>
            <br></br>
            <p><b>Add Plans</b></p>
            <hr></hr>
            {errorArray.map(item=><p className = "alert alert-danger">{item}</p>)}
            
            { !isLoading ? <form className = "m-2" onSubmit={e=>handleSubmit(e)}> 
                {ISP && ISP.length > 0 ? <div className="row m-1">
                    <label className="col-4">Plan provider</label>
                    <select required className="col-4 form-control" name="ispId" value = {data["ispId"]} onChange = {e=>onChange(e)}>
                        <option value = "">Select</option>
                        {ISP.map(item=><option value = {item["id"]}>{item["providerCode"]}</option>)}
                    </select>
                </div> : <p>... Loading</p>} 
                
                <div className="row m-1">
                    <label className="col-4">Plan Type</label>
                    <select required className="col-4 form-control" name="planDescription" value = {data["planDescription"]} onChange = {e=>onChange(e)}>
                        <option value = "">Select</option>
                        <option value = "BOOTSTRAP">BOOTSTRAP</option>
                        <option value = "COMMERCIAL">COMMERCIAL</option>
                        <option value = "TOPUP">TOPUP</option>
                        <option value = "RECHARGE">RECHARGE</option>
                    </select>
                </div>
                <div className="row m-1">
                    <label className="col-4">Validity Month</label>
                    <select required className="col-4 form-control" name="planValidityMonth" value = {data["planValidityMonth"]} onChange = {e=>onChange(e)}>
                        <option value = "">Select</option>
                        <option value = "1">1 Month</option>
                        <option value = "2">2 Months</option>
                        <option value = "3">3 Months</option>
                        <option value = "6">6 Months</option>
                        <option value = "12">12 Months</option>
                        <option value = "13">13 Months</option>
                        <option value = "24">24 Months</option>
                        <option value = "25">25 Months</option>
                    </select>
                </div>
                <div className="row m-1"> 
                    <label className="col-4">Data Limit / month</label>
                    <select required className="col-4 form-control" name="dataLimitMB" value = {data["dataLimitMB"]} onChange = {e=>onChange(e)}>
                        <option value = "">Select</option>
                        <option value = "10">10 MB</option>
                        <option value = "25">25 MB</option>
                        <option value = "50">50 MB</option>
                        <option value = "100">100 MB</option>
                        <option value = "250">250 MB</option>
                        <option value = "500">500 MB</option> 
                    </select>
                </div>
                <div className="row m-1">
                    <label className="col-4">SMS Limit / month</label>
                    <select required className="col-4 form-control" name="smsLimitCount" value = {data["smsLimitCount"]} onChange = {e=>onChange(e)}>
                        <option value = "">Select</option>
                        <option value = "10">10 SMS</option>
                        <option value = "25">25 SMS</option>
                        <option value = "50">50 SMS</option>
                        <option value = "100">100 SMS</option>
                    </select>
                </div>
                
                <div>
                    <input className="btn btn-primary" value="submit" type="submit"></input>
                </div>
            </form> : <LoadingWidget />}
        </>
    )

}

export default AddSIMPlan