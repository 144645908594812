const url = "https://iotsim.co.in/"

export async function getPlansList(){
    
    let data = sessionStorage.getItem("token");

    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + data);

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders,
      };
      
    let response = await fetch(url+"api/Plans/getplan", requestOptions)
    const data_response = await response.json()
    return data_response
}

export async function addESIMPlan(data){
    let token = sessionStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);
    
    data["planValidityMonth"] = parseInt(data["planValidityMonth"])
    data["dataLimitMB"] = parseInt(data["dataLimitMB"])
    data["smsLimitCount"] = parseInt(data["smsLimitCount"])

    var raw = JSON.stringify(data);    
    console.log("raw", raw)

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };

    try {
        const response = await fetch(url+"api/Plans/addplan", requestOptions)
        const data_response = await response.json()
        return data_response
    } catch (e) {
        return e
    }    
}


export async function updateESIMPlan(data){
    let token = sessionStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);
    
    data["planValidityMonth"] = parseInt(data["planValidityMonth"])
    data["dataLimitMB"] = parseInt(data["dataLimitMB"])
    data["smsLimitCount"] = parseInt(data["smsLimitCount"])
    
    var raw = JSON.stringify(data);    

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
    
    try {
        const response = await fetch(url+"api/Plans/updplan", requestOptions)
        const data_response = await response.json()
        return data_response
    } catch (e) {
        return e
    }
}