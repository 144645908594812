import { Outlet, Link } from "react-router-dom";

const SIMPlans = () => {

    return (
        <>
            <div id="sidebar" className="m-2">
                <nav className="m-2">
                    <ul className ="nav nav-tabs">
                        <li className="nav-item">
                            <Link className = "nav-link font-weight-bold" to={`viewSIMList`}>All SIM Plans</Link>
                        </li>
                        <li className="nav-item">
                            <Link className = "nav-link font-weight-bold" to={`addSIMPlan`}>Add SIM Plan</Link>
                        </li>
                        {/*<li className="nav-item">
                            <Link className = "nav-link font-weight-bold" to={`editSIMPlan`}>Edit SIM Plan</Link>
                        </li>*/} 
                    </ul>
                </nav>      
            </div>

            <div className="m-2">
                <Outlet />
            </div>
        </>
    )

}

export default SIMPlans