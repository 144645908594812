import {useContext} from "react"
import { ManufacturerContext } from "../../manufacturerContext"
import DynamicTable from "../components/Table"

const ViewManufacturerList = () => {

    const {manufacturers} = useContext(ManufacturerContext);

    return (
        <>
        {manufacturers && manufacturers.length > 0 ? <DynamicTable data = {manufacturers} sequence = {["code","name", "gst", "address", "city", "state", "pin", "pocName", "email", "contact","createdBy", "kycDocument", "otherDocument", "createdBy" ]} links={"/manufacturers/manufacturerDetail/"}/> : <p>No Manufacturer created on the platform Yet!!</p>}
    </>

    )
}

export default ViewManufacturerList