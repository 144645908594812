import {useState, useContext} from "react";
import readXlsxFile from 'read-excel-file';
import { getSingleESIMDetails, assignESIM } from "../../API/inventory";
import DynamicTable from "../components/Table";
import { ManufacturerContext } from "../../manufacturerContext"
import {ESIMInventoryContext} from "../../inventoryContext";
import LoadingWidget from "../components/loading"
import {useNavigate} from "react-router-dom";
import ExcelDownloadButton from "../components/downloadExcel"

const AssignInventory = () => {

    const {manufacturers} = useContext(ManufacturerContext);
    const {updateInventory, esimInventory} = useContext(ESIMInventoryContext)
    const navigate = useNavigate()

    const [data, setData] = useState({})
    const [bulkUpload, setBulkUpload] = useState([])
    const [errorArray, setErrorArray] = useState([])
    const [singleICCIDSelected, setSingleICCIDSelected] = useState(null)
    const [selectedESIMDetail, setSelectedESIMDetail] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    const handleFetch = async (e) => {
        e.preventDefault();
        var response = await getSingleESIMDetails(singleICCIDSelected)
        setSelectedESIMDetail(response["inventory"])
    }

    const onChange = (e) => {
        const data_new = data
        data_new[e.target.name] = e.target.value
        setData(data_new)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        var data_upload = selectedESIMDetail
        Object.keys(data).forEach(item=> data_upload[item]=data[item])
        data_upload["esim_state"] = "assigned"
        await assignESIM(data_upload)
        updateInventory()
        setIsLoading(false)
        navigate('/esimInventory/inventoryList')
    }

    const handleBulkUpload = async (e) => {
        e.preventDefault();
        const manufactures_code = manufacturers.map(item=>item["code"])

        setIsLoading(true)
        let response_array = []
        if (errorArray.length === 0){
            let manufacturerTagMismatch = []
            for (const item of bulkUpload){
                console.log("bulkUploadItem", item)
                if(!manufactures_code.includes(item["manufacturer"])){
                    manufacturerTagMismatch.push(item)
                    continue
                }
                var response = await assignESIM(item)
                response_array.push(response)
                console.log("response", response)
                }
            
            updateInventory()
        }
        setIsLoading(false)
        navigate('/esimInventory/inventoryList')   
    }

    const onBulkUpload = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const rows = await readXlsxFile(e.target.files[0])  
        const activated_required_array = ['iccid', 'manufacturer', 'amount']
        const activated_check_array = ['primary_operator', 'primary_imsi', 'primary_msisdn', 'fallback_operator', 'fallback_imsi', 'fallback_msisdn','esim_state', 'esim_status', 'updated_by', 'plan', 'primary_sim_activation_type', 'primary_sim_activation_date', 'primary_sim_expiry_date', 'primary_operator_data_limit', 'primary_status', 'fallback_activation_type', 'fallback_sim_activation_date', 'fallback_sim_expiry_date', 'fallback_operator_data_limit', 'fallback_status']
        const filtered_check_extra = rows[0].filter(item=> activated_check_array.includes(item))
        const filtered_check_required = activated_required_array.filter(item=> !rows[0].includes(item))
        if(filtered_check_extra.length > 0 || filtered_check_required.length > 0){
            const extra_items = filtered_check_extra.map(item=>"Please check " + item + " is extra header for data")
            const missing_items = filtered_check_required.map(item=>"Please check " + item + " is not available in the header for data")
            
            setErrorArray(extra_items.concat(missing_items))
        }

        const data_array = rows.shift()
            const data_object = {}
            for (const y of rows){
                let index = 0
                let new_obj = {}
                for(const x of data_array) {
                    new_obj[x]=y[index]
                    index=index+1
                }
                index =0
                data_object[y[0]] = new_obj  
            }
        
        const bulk_upload_data = Object.values(data_object)

        var bulk_fetched_data = []
        
        const esimInventoryObject = {}
        esimInventory.forEach(itemInventory=>esimInventoryObject[itemInventory["iccid"]] = itemInventory)
        
        for (let item of bulk_upload_data){
            var response = esimInventoryObject[item["iccid"]]
            var data_upload = response
            Object.keys(item).forEach(item_looped=> data_upload[item_looped]=item[item_looped].toString())
            data_upload["esim_state"] = "assigned"
            bulk_fetched_data.push(data_upload)
        }
        setBulkUpload(bulk_fetched_data)        
        setIsLoading(false)
    }

    return (
        <>
            {!isLoading ? <div>
            <br></br>
                <p><b>Assign Inventory to Manufacturer</b></p>
                <hr></hr>
                {errorArray.map(item=><p>{item}</p>)}                
                <div className="row m-2">
                    <label className="col-4"><b>Bulk Upload</b></label>
                    <input className="col-4 form-control" name="bulkUpload" type="file" onChange={e=>onBulkUpload(e)}></input>
                    <div className="col-2 ml-2">
                        <ExcelDownloadButton  headerData={[{iccid:"123456789", manufacturer:"GTROPY", amount:"1400", imei:"973480658406", ipwhitelist1:"10.1.1.1", ipwhitelist2:"10.1.1.2", ipwhitelist3:"10.1.1.3", ipwhitelist4:"10.1.1.4", smsnumber1:"987654321", smsnumber2:"987654321", smsnumber3:"987654321", smsnumber4:"987654321"}]} fileName={"InventoryAllocation"}/>  
                    </div>
                </div>
                <hr></hr>

                {bulkUpload.length===0? 
                <div>
                <form className = "m-2">
                
                    <div className="row m-1">
                        <label className="col-4">ICCID Number</label>
                        <input className="col-4 form-control" name="iccid" type="text" onChange={e=>setSingleICCIDSelected(e.target.value)}></input>
                        <button className="btn btn-primary ml-2" onClick={e=>handleFetch(e)}>Fetch</button>
                    </div> 
                    
                </form>
                {selectedESIMDetail ? 
                <div>
                    <p><b>Selected ESIM</b></p>
                    <DynamicTable data = {[selectedESIMDetail]} sequence = {["iccid", 'plan', "primary_operator", "primary_imsi", "primary_msisdn",  'primary_sim_activation_type', 'primary_sim_activation_date', 'primary_sim_expiry_date', 'primary_operator_data_limit', 'primary_status',"fallback_operator", "fallback_imsi", "fallback_msisdn", 'fallback_activation_type', 'fallback_sim_activation_date', 'fallback_sim_expiry_date', 'fallback_operator_data_limit', 'fallback_status']} />
                    {manufacturers && manufacturers.length > 0 ? <div className="row m-1">
                        <label className="col-4">Manufacturer</label>
                        <select className="col-4 form-control" name="manufacturer" value = {data["manufacturer"]} onChange = {e=>onChange(e)}>
                            <option value = "">Select</option>
                            {manufacturers.map(item=><option value = {item["code"]}>{item["code"]}</option>)}
                        </select>
                    </div> : <p>... Loading</p>}
                    <div className="row m-1">
                        <label className="col-4">Amount</label>
                        <input className="col-4 form-control" name="amount" type="text" onChange={e=>onChange(e)}></input>
                    </div>
                    <p><b>Mapping and Whitelisting Detail</b></p>
                    
                    <div className="row m-1">
                        <label className="col-4">Device IMEI</label>
                        <input className="col-4 form-control" name="imei" type="text" onChange={e=>onChange(e)}></input>
                    </div>
                    <div className="row m-1">
                        <label className="col-4">IP1 address</label>
                        <input className="col-4 form-control" name="ipwhitelist1" type="text" onChange={e=>onChange(e)}></input>
                    </div>
                    <div className="row m-1">
                        <label className="col-4">IP2 address</label>
                        <input className="col-4 form-control" name="ipwhitelist2" type="text" onChange={e=>onChange(e)}></input>
                    </div>
                    <div className="row m-1">
                        <label className="col-4">IP3 address</label>
                        <input className="col-4 form-control" name="ipwhitelist3" type="text" onChange={e=>onChange(e)}></input>
                    </div>
                    <div className="row m-1">
                        <label className="col-4">IP4 address</label>
                        <input className="col-4 form-control" name="ipwhitelist4" type="text" onChange={e=>onChange(e)}></input>
                    </div>
                    <div className="row m-1">
                        <label className="col-4">SMS Number 1</label>
                        <input className="col-4 form-control" name="smsnumber1" type="text" onChange={e=>onChange(e)}></input>
                    </div>
                    <div className="row m-1">
                        <label className="col-4">SMS Number 2</label>
                        <input className="col-4 form-control" name="smsnumber2" type="text" onChange={e=>onChange(e)}></input>
                    </div>
                    <div className="row m-1">
                        <label className="col-4">SMS Number 3</label>
                        <input className="col-4 form-control" name="smsnumber3" type="text" onChange={e=>onChange(e)}></input>
                    </div>
                    <div className="row m-1">
                        <label className="col-4">SMS Number 4</label>
                        <input className="col-4 form-control" name="smsnumber4" type="text" onChange={e=>onChange(e)}></input>
                    </div>
                    <button className='btn btn-primary m-1' onClick={e=>handleSubmit(e)}>Save</button>              
                </div>
                :null}    
            </div>
                :
                <div>
                    <button className='btn btn-primary m-1' onClick={e=>handleBulkUpload(e)}>Save</button>
                    <button className='btn btn-info m-1' onClick={e=>{setBulkUpload([]); setErrorArray([])}}>refresh</button>
                    <DynamicTable data = {bulkUpload} sequence = {["iccid", 'plan',"manufacturer", "amount", "ipwhitelist1", "ipwhitelist2", "ipwhitelist3", "ipwhitelist4", "smsnumber1", "smsnumber2", "smsnumber3", "smsnumber4", "primary_operator", "primary_imsi", "primary_msisdn",  'primary_sim_activation_type', 'primary_sim_activation_date', 'primary_sim_expiry_date', 'primary_operator_data_limit', 'primary_status',"fallback_operator", "fallback_imsi", "fallback_msisdn", 'fallback_activation_type', 'fallback_sim_activation_date', 'fallback_sim_expiry_date', 'fallback_operator_data_limit', 'fallback_status']}/>
                </div> 
                }
            </div> : <LoadingWidget />}
        </>
    )
}

export default AssignInventory