import React, {createContext, useState, useEffect, useContext} from 'react';
import {getManufacturerList} from "./API/maufacturer"
import { UserContext } from './userContext';


const ManufacturerContext = createContext(undefined);

function ManufacturerProvider({ children }) {
  
  const userDetails = useContext(UserContext);
  const [manufacturers, setManufacturers] = useState(null);

  useEffect(()=>{
    const getManufacturers = async () => {
        const response_api = await getManufacturerList()
        if(response_api.status){
            setManufacturers(response_api["manufacturer"])
        }
    }  
    
    if (sessionStorage.getItem("token")!==null){
        getManufacturers()    
    } 
  },[userDetails])

  let updateManufacturer = async () => {
    const response_api = await getManufacturerList()
        if(response_api.status){
            setManufacturers(response_api["manufacturer"])
        }
  }

  return (
    <ManufacturerContext.Provider value={{manufacturers, updateManufacturer}}>
            {children}
    </ManufacturerContext.Provider>
  );
}

export { ManufacturerProvider, ManufacturerContext};
