import { Outlet, Link } from "react-router-dom";


const Inventory = () => {
    return (
        <>
            <div id="sidebar" className="m-2">
                <nav className="m-2">
                    <ul className ="nav nav-tabs">
                        <li className="nav-item">
                            <Link className = "nav-link font-weight-bold" to={`inventoryList`}>Inventory List</Link>
                        </li>
                        <li className="nav-item">
                            <Link className = "nav-link font-weight-bold" to={`addInventory`}>Add ESIM</Link>
                        </li>
                        <li className="nav-item">
                            <Link className = "nav-link font-weight-bold" to={`activateInventory`}>ESIM Operations</Link>
                        </li>
                        <li className="nav-item">
                            <Link className = "nav-link font-weight-bold" to={`assignInventory`}>Assign ESIM</Link>
                        </li>
                        {/*<li className="nav-item">
                            <Link className = "nav-link font-weight-bold" to={`inventoryUpdate`}>Inventory Update</Link>
                        </li>*/}
                                                                      
                    </ul>
                </nav>    
            </div>
            <div className="m-2">
                <Outlet />
            </div>
        </>
    )

}

export default Inventory