import { useState, useContext } from "react";
import readXlsxFile from 'read-excel-file';
import { addESIMInventory } from "../../API/inventory";
import DynamicTable from "../components/Table";
import { ISPContext } from "../../ispContext"
import {ESIMInventoryContext} from "../../inventoryContext"
import LoadingWidget from "../components/loading"
import {useNavigate} from "react-router-dom";
import ExcelDownloadButton from "../components/downloadExcel"
import updateESIMState from "./esimUpdate"

const AddInventory = () => {

    const navigate = useNavigate()

    const {ISP} = useContext(ISPContext);
    const {updateInventory, esimInventory} = useContext(ESIMInventoryContext)

    const [data, setData] = useState({})
    const [bulkUpload, setBulkUpload] = useState([])
    const [errorArray, setErrorArray] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    
    const onChange = (e) => {
        const data_new = data
        data_new[e.target.name] = e.target.value
        setData(data_new)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const isICCIDExists = esimInventory ? esimInventory.filter(item => item["iccid"] === data["iccid"]) : null
        

        if(isICCIDExists.length === 0){
            var data_upload = updateESIMState("add", data)
            console.log("data_upload", data_upload)
            var response = await addESIMInventory(data_upload)     
            if (!response["status"]){
                setErrorArray(errorArray.concat(response["message"]))
                setIsLoading(false)
            } else {
                updateInventory()
                setIsLoading(false)
                navigate('/esimInventory/inventoryList')
            }
        } else {
            setErrorArray(errorArray.concat("ICCID already exists"))
            setIsLoading(false)
        }
    }

    const handleBulkUpload = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const isp_providers = ISP.map(item=>item["providerCode"])
        var errorsInICCID = [...errorArray]

        if (errorArray.length === 0){
            let operatorTagMismatch = []
            for (const item of bulkUpload){
                if(!isp_providers.includes(item["primary_operator"]) || !isp_providers.includes(item["fallback_operator"])){
                    operatorTagMismatch.push(item)
                    continue
                }
                var data_upload = updateESIMState("add", item)
                
                var response = await addESIMInventory(data_upload)
                if (!response["status"]){
                    errorsInICCID.push(data_upload["iccid"] + "   " + response["message"])
                }
        }
    }
    
    setIsLoading(false)
    if (errorsInICCID.length === 0 ){
        navigate('/esimInventory/inventoryList')
    } else {
        setErrorArray(errorsInICCID)
    }

    updateInventory()
}

    const onBulkUpload = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const rows = await readXlsxFile(e.target.files[0])  

        const notActivated_required_array = ['iccid', 'primary_operator', 'primary_imsi', 'primary_msisdn', 'fallback_operator', 'fallback_imsi', 'fallback_msisdn']
        const notActivated_check_array = ['esim_state', 'esim_status', 'plan', 'primary_sim_activation_type', 'primary_sim_activation_date', 'primary_sim_expiry_date', 'primary_operator_data_limit', 'primary_status', 'fallback_activation_type', 'fallback_sim_activation_date', 'fallback_sim_expiry_date', 'fallback_operator_data_limit', 'fallback_status', 'manufacturer', 'amount', 'updated_by']
        const filtered_check_extra = rows[0].filter(item=> notActivated_check_array.includes(item))
        const filtered_check_required = notActivated_required_array.filter(item=> !rows[0].includes(item))
        if(filtered_check_extra.length > 0 || filtered_check_required.length > 0){
            const extra_items = filtered_check_extra.map(item=>"Please check " + item + " is extra header for data")
            const missing_items = filtered_check_required.map(item=>"Please check " + item + " is not available in the header for data")
            setErrorArray(extra_items.concat(missing_items))
        }

        const data_array = rows.shift()
            const data_object = {}
            for (const y of rows){
                let index = 0
                let new_obj = {}
                for(const x of data_array) {
                    new_obj[x]=y[index]
                    index=index+1
                }
                index =0
                data_object[y[0]] = new_obj  
            }
        const bulk_upload_data = Object.values(data_object)
        const existingICCID = esimInventory.map(item=>item["iccid"])
        const filtered_bulk_upload = bulk_upload_data.filter(item=>!existingICCID.includes(item["iccid"].toString()))
        const existing_esimICCID = bulk_upload_data.filter(item=>!filtered_bulk_upload.includes(item))
        if(existing_esimICCID.length > 0){
            let errorsInICCID = [] 
            existing_esimICCID.forEach(item=>errorsInICCID.push(item["iccid"] + "   ICCID already exists in the DB"))
            setErrorArray(errorsInICCID)
        }
        setBulkUpload(filtered_bulk_upload)
        setIsLoading(false)
    }
    
    return (
        <>
            <br></br>
            <p><b>Add ESIM Inventory</b></p>
            <hr></hr>

            {errorArray.map(item=><p className = "alert alert-danger">{item}</p>)}                
            <div className="row m-2">
                <label className="col-4"><b>Bulk Upload</b></label>
                <input className="col-4 form-control" name="bulkUpload" type="file" onChange={e=>onBulkUpload(e)}></input>
                <div className="col-2 ml-2">
                    <ExcelDownloadButton  headerData={[{iccid: "123456789", primary_operator: "Airtel", primary_imsi:"987654321", primary_msisdn: "9876543", fallback_operator:"BSNL", fallback_imsi:"36636374747", fallback_msisdn:"'34698086906"}]} fileName={"InventoryUpload"}/>      
                </div>
                
            </div>
            <hr></hr>
            {bulkUpload.length===0? 
            <div>
            {!isLoading ?    
            <form className = "m-2">

                <div className="row m-1">
                    <label className="col-4">ICCID Number</label>
                    <input required className="col-4 form-control" name="iccid" type="text" onChange={e=>onChange(e)}></input>
                </div> 
                {ISP && ISP.length > 0 ? <div className="row m-1">
                    <label className="col-4">Primary Operator</label>
                    <select required className="col-4 form-control" name="primary_operator" value = {data["primary_operator"]} onChange = {e=>onChange(e)}>
                        <option value = "">Select</option>
                        {ISP.map(item=><option value = {item["providerCode"]}>{item["providerCode"]}</option>)}
                    </select>
                </div> : <p>... Loading</p>} 
                
                <div className="row m-1">
                    <label className="col-4">Primary IMSI</label>
                    <input required className="col-4 form-control" name="primary_imsi" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">Primary MSISDN (0 if not available)</label>
                    <input required className="col-4 form-control" name="primary_msisdn" type="text" onChange={e=>onChange(e)}></input>
                </div>
                
                {ISP && ISP.length > 0 ? <div className="row m-1"> 
                    <label className="col-4">Secondary Operator</label>
                    <select required className="col-4 form-control" name="fallback_operator" value = {data["fallback_operator"]} onChange = {e=>onChange(e)} >
                        <option value = "">Select</option>
                        {ISP.map(item=><option value = {item["providerCode"]}>{item["providerCode"]}</option>)}
                    </select>
                </div> : <p>... Loading</p>} 
                <div className="row m-1"> 
                    <label className="col-4">Secondary IMSI</label>
                    <input required className="col-4 form-control" name="fallback_imsi" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">Secondary MSISDN (0 if not available)</label>
                    <input required className="col-4 form-control" name="fallback_msisdn" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <button className="btn btn-primary" onClick={e=>handleSubmit(e)}>Save</button>      
            </form> : <LoadingWidget />}
        
        </div>
            :
            <div>
                <button className='btn btn-primary m-1' onClick={e=>handleBulkUpload(e)}>Save</button>
                <button className='btn btn-warning m-1' onClick={e=>{setBulkUpload([]); setErrorArray([])}}>refresh</button>
                <DynamicTable data = {bulkUpload} sequence = {["iccid", "primary_operator", "primary_imsi", "primary_msisdn", "fallback_operator", "fallback_imsi", "fallback_msisdn"]} links={"/manufacturers/manufacturer_detail/"}/>
            </div> 
            }
        </>
    )
}

export default AddInventory