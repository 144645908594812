import React from 'react';
import * as XLSX from 'xlsx';
import {FaDownload} from "react-icons/fa" 

const ExcelDownloadButton = (params) => {

  const headerData = params.headerData
  const fileName = params.fileName

  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(headerData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelData = XLSX.write(workbook, { type: 'binary', bookType: 'xlsx' });
    const blob = new Blob([s2ab(excelData)], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName+'.xlsx';
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  return (
    <button onClick={handleDownload}>
      <FaDownload size="2rem" />
    </button>
  );
};

export default ExcelDownloadButton;