import {useState, useEffect} from "react"
import {getUserList} from "../../API/user"
import DynamicTable from "../components/Table"

export default function UserList() {

    const [internalUserList, setInternalUserList] = useState(null)
    const [externalUserList, setExternalUserList] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        const getUser = async() =>{
            const user_list = await getUserList()
            if(user_list["status"]){
                setExternalUserList(user_list["userlist"].filter(item=>item["role"] === "ExternalUser"))
                setInternalUserList(user_list["userlist"].filter(item=>item["role"] === "SuperUser"))
            } else {
                setError("Something went Wrong!!")
            }
        }
        getUser()
    },[])

    return (
        <div>
            <div>
                <p><b>External User List</b></p>
                {externalUserList && externalUserList.length > 0 ? <DynamicTable data = {externalUserList} sequence = {["username", "api_token", "createdDt"]} /> : <p>...Fetching</p>}
            </div>
            <div>
                <p><b>Internal User List</b></p>
                {internalUserList && internalUserList.length > 0 ? <DynamicTable data = {internalUserList} sequence = {["username", "api_token", "createdDt"]} /> : <p>...Fetching</p>}
            </div>
            
        </div>
        

    )
}