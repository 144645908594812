import {useState, useContext} from "react";
import readXlsxFile from 'read-excel-file';
import {getSingleESIMDetails, activateESIM } from "../../API/inventory";
import DynamicTable from "../components/Table";
import {ESIMInventoryContext} from "../../inventoryContext";
import LoadingWidget from "../components/loading";
import {useNavigate} from "react-router-dom";
import {SIMPlansContext} from "../../simPlansContext"
import ExcelDownloadButton from "../components/downloadExcel"

const ActivateInventory = () => {

    const {updateInventory, esimInventory} = useContext(ESIMInventoryContext)
    const {SIMPlans} = useContext(SIMPlansContext)    

    const navigate = useNavigate()

    const [data, setData] = useState({})
    const [bulkUpload, setBulkUpload] = useState([])

    const [activateFallback, setActivateFallback] = useState(false)
    const [errorArray, setErrorArray] = useState([])
    
    const [singleICCIDSelected, setSingleICCIDSelected] = useState(null)
    const [selectedESIMDetail, setSelectedESIMDetail] = useState(null)

    const [filteredPrimaryPlans, setFilteredPrimaryArrays] = useState([])
    const [primarySimPlan, setPrimarySimPlan] = useState(null)

    const [filteredSecondaryPlans, setFilteredSecondaryArrays] = useState([])
    const [fallbackSimPlan, setFallbackSimPlan] = useState(null)

    const [primaryActivationDate, setPrimaryActivationDate] = useState(null)
    const [secondaryActivationDate, setSecondaryActivationDate] = useState(null)

    const [isLoading, setIsLoading] = useState(false)


    let primarySIMSelectedPlan = primarySimPlan ? SIMPlans.filter(item=>item["planTag"]===primarySimPlan)[0] : null
    let secondarySIMSelectedPlan = fallbackSimPlan ? SIMPlans.filter(item=>item["planTag"]===fallbackSimPlan)[0] : null

    const handleFetch = async (e) => {
        e.preventDefault();
        var response = await getSingleESIMDetails(singleICCIDSelected)
        if (!response["status"]){
            let modified_error_array = errorArray
            modified_error_array.push(response["message"])
            setErrorArray(modified_error_array)
        } else {
            setSelectedESIMDetail(response["inventory"])
            const filterPrimaryArray = SIMPlans.filter(item=> response["inventory"]["primary_operator"] === item["planTag"].split("_")[0] && (item["planDescription"] === "BOOTSTRAP" || item["planDescription"] === "COMMERCIAL"))
            setFilteredPrimaryArrays(filterPrimaryArray)

            const filterSecondaryArray = SIMPlans.filter(item=> response["inventory"]["fallback_operator"] === item["planTag"].split("_")[0] && (item["planDescription"] === "BOOTSTRAP" || item["planDescription"] === "COMMERCIAL"))
            setFilteredSecondaryArrays(filterSecondaryArray)
        }
    }

    const onChange = (e) => {
        const data_new = data
        data_new[e.target.name] = e.target.value
        setData(data_new)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        var data_upload = selectedESIMDetail
        Object.keys(data).forEach(item=> data_upload[item]=data[item])
        
        data_upload["primary_sim_activation_type"] = primarySIMSelectedPlan["planDescription"]
        data_upload["primary_operator_data_limit"] = primarySIMSelectedPlan["dataLimitMB"].toString() + primarySIMSelectedPlan["smsLimitCount"].toString()
        data_upload["primary_sim_activation_date"] = new Date(primaryActivationDate)
        data_upload["primary_sim_expiry_date"] = new Date(getExpiryDate(primaryActivationDate, primarySIMSelectedPlan["planValidityMonth"]))
        data_upload["primary_status"] = "Active"

        if(activateFallback){
            data_upload["fallback_activation_type"] = secondarySIMSelectedPlan["planDescription"]
            data_upload["fallback_operator_data_limit"] = secondarySIMSelectedPlan["dataLimitMB"].toString() + secondarySIMSelectedPlan["smsLimitCount"].toString()
            data_upload["fallback_sim_activation_date"] = new Date(secondaryActivationDate)
            data_upload["fallback_sim_expiry_date"] = new Date(getExpiryDate(secondaryActivationDate, secondarySIMSelectedPlan["planValidityMonth"]))
            data_upload["fallback_status"] = "Active"
        }

        data_upload["plan"] = activateFallback ? primarySimPlan + "_" + fallbackSimPlan.split("_")[0] : primarySimPlan
        data_upload["updated_on"] = new Date()
        data_upload["esim_status"] = "Active"
        var response = await activateESIM(data_upload)
        updateInventory()
        setIsLoading(false)
        navigate('/esimInventory/inventoryList')
    }

    const handleBulkUpload = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        let response_array = []
        if (errorArray.length === 0){
            for (const item of bulkUpload){
            var response = await activateESIM(item)
            response_array.push(response)
            }
        updateInventory()
        }
        setIsLoading(false)
        navigate('/esimInventory/inventoryList')
    }

    const onBulkUpload = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const rows = await readXlsxFile(e.target.files[0])  

        const data_array = rows.shift()
        const data_object = {}
        for (const y of rows){
            let index = 0
            let new_obj = {}
            for(const x of data_array) {
                new_obj[x]=y[index]
                index=index+1
            }
            index =0
            data_object[y[0]] = new_obj  
        }
        
        const bulk_upload_data = Object.values(data_object)
        
        var bulk_fetched_data = []
        const simPlansObject = {}
        SIMPlans.forEach(itemPlan=>simPlansObject[itemPlan["planTag"]] = itemPlan)

        
        const esimInventoryObject = {}
        esimInventory.forEach(itemInventory=>esimInventoryObject[itemInventory["iccid"]] = itemInventory)
        
        for (let item of bulk_upload_data){
            var response = esimInventoryObject[item["iccid"]]
            var data_upload = response
            
            data_upload["primary_msisdn"] = Number(data_upload["primary_msisdn"]) || item["primary_msisdn"] || 0
            data_upload["fallback_msisdn"] = Number(data_upload["fallback_msisdn"]) || item["fallback_msisdn"] || 0

            let primaryPlanDetail = item["primary_sim_plan"] ? simPlansObject[item["primary_sim_plan"]] : null
            let secondaryPlanDetail = item["fallback_sim_plan"] ? simPlansObject[item["fallback_sim_plan"]] : null

            if(primaryPlanDetail){
                data_upload["primary_sim_activation_type"] = primaryPlanDetail["planDescription"]
                data_upload["primary_operator_data_limit"] = primaryPlanDetail["dataLimitMB"]
                data_upload["primary_sim_activation_date"] = new Date(item["primary_sim_activation_date"])
                data_upload["primary_sim_expiry_date"] = new Date(getExpiryDate(item["primary_sim_activation_date"], primaryPlanDetail["planValidityMonth"]))
                data_upload["primary_status"] = "Active"                
            }

            if(secondaryPlanDetail){
                data_upload["fallback_activation_type"] = secondaryPlanDetail["planDescription"]
                data_upload["fallback_operator_data_limit"] = secondaryPlanDetail["dataLimitMB"]
                data_upload["fallback_sim_activation_date"] = new Date(item["fallback_sim_activation_date"])
                data_upload["fallback_sim_expiry_date"] = new Date(getExpiryDate(item["fallback_sim_activation_date"], secondaryPlanDetail["planValidityMonth"]))
                data_upload["fallback_status"] = "Active"
            }

            data_upload["plan"] = secondaryPlanDetail ? item["primary_sim_plan"] + "_" + item["fallback_sim_plan"].split("_")[0]  : item["primary_sim_plan"]
            data_upload["updated_on"] = new Date()
            data_upload["esim_status"] = "Active"
        
            bulk_fetched_data.push(data_upload)
        }

        setBulkUpload(bulk_fetched_data)
        setIsLoading(false)        
    }

    
    const getExpiryDate = (date, months) => {
        const monthToDayMap = {1:30, 2:60, 3:90, 6:180, 12: 365, 13: 395, 24: 730, 25: 760}
        let result = new Date(date)
        result.setDate(result.getDate() + monthToDayMap[months]);
        return result.toDateString()
    }

    return (
        <>
        {!isLoading ? <div>
        <br></br>
            <p><b>Activate Inventory</b></p>
            <hr></hr>
            <div className="row m-1">
                    <label className="col-4">Activate FallBack SIM</label>
                    <select className="col-4 form-control" name="activateFallback" value = {activateFallback} onChange = {()=>setActivateFallback(!activateFallback)} >
                        <option value = {false}>Not Required</option>
                        <option value = {true}>Required</option>
                    </select>
            </div>
            {errorArray.map(item=><p className = "alert alert-danger">{item}</p>)}                
            <div className="row m-2">
                <label className="col-4"><b>Bulk Upload</b></label>
                <input className="col-4 form-control" name="bulkUpload" type="file" onChange={e=>onBulkUpload(e)}></input>
                <div className="col-2 ml-2">
                    <ExcelDownloadButton  headerData={[{iccid:"123456789", primary_msisdn:"98765478", primary_sim_plan:"Airtel_commercial_12M_250MB_50SMS",	primary_sim_activation_date:"30-05-2023", fallback_msisdn:"87654321", fallback_sim_plan:"BSNL_commercial_12M_100MB_50SMS", fallback_sim_activation_date:"30-05-2023"}]} fileName={"InventoryActivation"}/>  
                </div>
            </div>
            <hr></hr>

            {bulkUpload.length===0? 
            <div>
            <form className = "m-2">
                <div className="row m-1">
                    <label className="col-4">ICCID Number</label>
                    <input className="col-4 form-control" name="iccid" type="text" onChange={e=>setSingleICCIDSelected(e.target.value)}></input>
                    <button className="btn btn-primary ml-2" onClick={e=>handleFetch(e)}>Fetch</button>
                </div> 
            </form>
            {selectedESIMDetail ? 
            <div>
                <p><b>Selected ESIM</b></p>
                <DynamicTable data = {[selectedESIMDetail]} sequence = {["iccid", "primary_operator", "primary_imsi", "primary_msisdn", "fallback_operator", "fallback_imsi", "fallback_msisdn", "manufacturer"]} links={"/manufacturers/manufacturer_detail/"}/>
                
                {selectedESIMDetail["esim_status"] == "Active" ? <div>
                    <DynamicTable data = {[selectedESIMDetail]} sequence = {['plan', 'primary_sim_activation_type', 'primary_sim_activation_date', 'primary_sim_expiry_date', 'primary_operator_data_limit', 'primary_status', 'fallback_activation_type', 'fallback_sim_activation_date', 'fallback_sim_expiry_date', 'fallback_operator_data_limit', 'fallback_status']} links={"/manufacturers/manufacturer_detail/"}/>
                </div> :  null}
                
                <p><b>Primary Sim Operations</b></p>
                
                {selectedESIMDetail["primary_msisdn"] == 0 ? 
                
                <div className="row m-1">
                    <label className="col-4">Primary MSISDN</label>
                    <input required className="col-4 form-control" name="primary_msisdn" type="text" onChange={e=>onChange(e)}></input>
                </div> : null}
                <div className="row m-1">
                    <label className="col-4">Primary Plan</label>
                    <select required className="col-4 form-control" name="primary_sim_plan" value = {primarySimPlan} onChange = {e=>setPrimarySimPlan(e.target.value)} >
                        <option value = "">Select</option>
                        {filteredPrimaryPlans.length > 0 ? filteredPrimaryPlans.map(item=>{
                            return <option value = {item["planTag"]}>{item["planTag"]}</option>    
                        }): null}
                    </select>
                </div> 
                <div className="row m-1">
                    <label className="col-4">Primary Activation Type</label>
                    <p className="col-4">{primarySimPlan && primarySIMSelectedPlan ? primarySIMSelectedPlan["planDescription"] : "Select a Primary Plan"}</p>
                </div>
                <div className="row m-1">
                    <label className="col-4">Primary Data Limit</label>
                    <p className="col-4">{primarySimPlan && primarySIMSelectedPlan ? primarySIMSelectedPlan["dataLimitMB"] + " MB" : "Select a Primary Plan"}</p>
                </div>
                <div className="row m-1">
                    <label className="col-4">Primary SMS Limit</label>
                    <p className="col-4">{primarySimPlan && primarySIMSelectedPlan ? primarySIMSelectedPlan["smsLimitCount"] + " SMS" : "Select a Primary Plan"}</p>
                </div>
                <div className="row m-1">
                    <label className="col-4">Primary Activation Date</label>
                    <input required className="col-4 form-control" name="primary_sim_activation_date" type="date" onChange={e=>setPrimaryActivationDate(e.target.value)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">Primary Expiry Date</label>
                    <p className="col-4">{primarySimPlan &&  primaryActivationDate ? getExpiryDate(primaryActivationDate, primarySIMSelectedPlan["planValidityMonth"]) : "Select a Primary Plan / Activation Date"}</p>
                </div>
                
                {activateFallback ? <div>
                    <p><b>Activate Fallback Number</b></p>
                
                    {selectedESIMDetail["fallback_msisdn"] == 0 ? <div className="row m-1">
                        <label className="col-4">Secondary MSISDN</label>
                        <input className="col-4 form-control" name="fallback_msisdn" type="text" onChange={e=>onChange(e)}></input>
                    </div> : null}
                    <div className="row m-1">
                        <label className="col-4">Secondary Plan</label>
                        <select required className="col-4 form-control" name="fallback_sim_plan" value = {fallbackSimPlan} onChange = {e=>setFallbackSimPlan(e.target.value)} >
                            <option value = "">Select</option>
                            {filteredSecondaryPlans.length > 0 ? filteredSecondaryPlans.map(item=>{
                                return <option value = {item["planTag"]}>{item["planTag"]}</option>    
                            }): null}
                        </select>
                    </div> 
                    <div className="row m-1">
                        <label className="col-4">Fallback Activation Type</label>
                        <p className="col-4">{fallbackSimPlan && secondarySIMSelectedPlan ? secondarySIMSelectedPlan["planDescription"] : "Select a Fallback Plan"}</p>
                    </div>
                    <div className="row m-1">
                        <label className="col-4">Fallback Data Limit</label>
                        <p className="col-4">{fallbackSimPlan && secondarySIMSelectedPlan ? secondarySIMSelectedPlan["dataLimitMB"] + " MB" : "Select a Fallback Plan"}</p>
                    </div>
                    <div className="row m-1">
                        <label className="col-4">Fallback SMS Limit</label>
                        <p className="col-4">{fallbackSimPlan && secondarySIMSelectedPlan ? secondarySIMSelectedPlan["smsLimitCount"] + " SMS" : "Select a Fallback Plan"}</p>
                    </div>
                    <div className="row m-1">
                        <label className="col-4">Fallback Activation Date</label>
                        <input className="col-4 form-control" name="fallback_sim_activation_date" type="date" onChange={e=>setSecondaryActivationDate(e.target.value)}></input> 
                    </div>
                    <div className="row m-1">
                        <label className="col-4">Fallback Expiry Date</label>
                        <p className="col-4">{fallbackSimPlan &&  secondaryActivationDate ? getExpiryDate(secondaryActivationDate, secondarySIMSelectedPlan["planValidityMonth"]) : "Select a Secondary Plan / Activation Date"}</p>
                    </div>
                    
                </div> : null} 
                <button className='btn btn-primary m-1' onClick={e=>handleSubmit(e)}>Save</button>              
            </div> 
            :null}    
        </div>
            :
            <div>
                <button className='btn btn-primary m-1' onClick={e=>handleBulkUpload(e)}>Save</button>
                <button className='btn btn-info m-1' onClick={e=>{setBulkUpload([]); setErrorArray([])}}>refresh</button>
                <DynamicTable data = {bulkUpload} sequence = {["iccid", 'plan', "primary_operator", "primary_imsi", "primary_msisdn",  'primary_sim_activation_type', 'primary_sim_activation_date', 'primary_sim_expiry_date', 'primary_operator_data_limit', 'primary_status',"fallback_operator", "fallback_imsi", "fallback_msisdn", 'fallback_activation_type', 'fallback_sim_activation_date', 'fallback_sim_expiry_date', 'fallback_operator_data_limit', 'fallback_status']}/>
            </div> 
            }
        </div> : <LoadingWidget/>}
    </>
    )
}

export default ActivateInventory