const url = "https://iotsim.co.in/"

export async function getManufacturerList(){
    
    let data = sessionStorage.getItem("token");

    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + data);

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders,    
      };
      
    try{
        let response = await fetch(url+"api/Manufacturer/getmanufacturer", requestOptions)
        const data_response = await response.json()
        return data_response
    } catch (e) {
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("username")
        sessionStorage.removeItem("isLoggedIn")
        return e
    }

}

export async function addManufacturer(data){
    
    let token = sessionStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    data["pin"] = parseInt(data["pin"])
    var raw = JSON.stringify(data);    
    console.log("raw", raw)

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
    try {
        const response = await fetch(url+"api/Manufacturer/addmanufacturer", requestOptions)
        const data_response = await response.json()
        return data_response
    } catch (e) {
        return e
    }
    
}

export async function editManufacturer(params){
    return "Edit ISP"
}