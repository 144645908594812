import {useState, useContext, useEffect} from "react"
import Navigation from "./Screens/components/navigation";
import {GiHamburgerMenu} from "react-icons/gi"
import { Outlet } from "react-router-dom";
import { UserContext } from "./userContext";
import { ISPContext } from "./ispContext";
import { ManufacturerContext } from "./manufacturerContext";
import LoginScreen from "./Screens/LoginScreens"

function App() {

  const [isToggled, setIsToggled] = useState(false)
  const [navWidth, setNavWidth] = useState("col-2")
  const userDetails = useContext(UserContext);
  const ispDetails = useContext(ISPContext);
  const manufacturerDetails = useContext(ManufacturerContext);
  
  const toggleNavigation = (e) => {
    setIsToggled(!isToggled)  
  }

  useEffect(()=>{
    if(isToggled){
      setNavWidth("col-1")
    } else if (!isToggled){
      setNavWidth("col-2")      
    }
  },[isToggled])

  return (
    <>
      <div style={{width:"100%", height:"100%"}}>
          <div className="p-3 text-white d-flex justify-content-between align-items-center" style={{backgroundColor:"#3d5a80"}}>
            <GiHamburgerMenu size="2rem" onClick={(e)=>{toggleNavigation(e)}}/>
            <h3 className="mr-2"><b>ESIM Inventory</b></h3>
          </div>
          { userDetails && (userDetails.isLoggedIn===true || userDetails.isLoggedIn==="true") && sessionStorage.getItem("username")!== null && sessionStorage.getItem("username")!== undefined ?
            ispDetails && manufacturerDetails ? 
              <div className="row m-0" style={{width:"100%", height:"100vh"}}>
                <div className={navWidth + " flex-fill"} style={{backgroundColor:" #3d5a80", width:"100%"}}>
                  <Navigation  toggleState = {isToggled}/>
                </div>
              
                <div id="detail" className="col-10">
                  <Outlet />
                </div>
            </div> : <p>....Loading</p> :  <div className="container row justify-content-center align-items-center">
                    <LoginScreen />
                </div>}
      </div>
    </>
  );
}

export default App;
