import {useState, useContext} from "react"
import {getSingleESIMDetails, updateESIM } from "../../API/inventory";
import LoadingWidget from "../components/loading"
import {useNavigate} from "react-router-dom";

const EditManufacturer = () => {
    return (
        <div>Edit Manufacturer</div>
    )
}

export default EditManufacturer