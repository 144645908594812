import {useState, useContext} from 'react';
import {addISP, getISPList} from "../../API/isp";
import {ISPContext} from "../../ispContext";
import LoadingWidget from "../components/loading";
import {useNavigate} from "react-router-dom";
import {addAPIDocumentStorage} from "../../API/storage";

const AddISP = () => {

    const navigate = useNavigate()

    const {updateISP} = useContext(ISPContext)
    const [data, setData] = useState({})
    const [ISPCodeError, setISPCodeError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    
    const onChange = (e) => {
        const data_new = data
        data_new[e.target.name] = e.target.value
        setData(data_new)
    }
    
    const fileUpload = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const uploadFile = await addAPIDocumentStorage(e.target.files)
        setIsLoading(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const isp_list = await getISPList()
        let isISPIDTaken = []

        if(isp_list.status){
            isISPIDTaken = isp_list["ispmaster"].filter(item=>item.providerCode === data.providerCode)
            console.log("isISPIDTaken", isISPIDTaken)
        }
         
        if (isISPIDTaken.length > 0){
            setISPCodeError("ISP Code already taken!!")
            setIsLoading(false)
        } else {
        var data_upload = data
        data_upload["document"] = "s3BucketISPProvider"
        //var response = await addISP(data_upload)
        updateISP()
        setIsLoading(false)
        navigate('/mobileOperator/viewOperatorList')
        }
    }
    
    return (
        <>
            {ISPCodeError ? <p className = "alert alert-danger">{ISPCodeError}</p> : null}
            {!isLoading ?
            <form className = "m-2" onSubmit={e=>handleSubmit(e)}>
                <div className="row m-1">
                    <label className="col-4">Mobile Operator ID</label>
                    <input className="col-4 form-control" name="providerCode" type="text" onChange={e=>onChange(e)}></input>
                </div> 
                <div className="row m-1">
                    <label className="col-4">Operator Name</label>
                    <input className="col-4 form-control" name="name" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">GST Number</label>
                    <input className="col-4 form-control" name="gst" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <p><b>Manufacturer Address</b></p>
                <div className="row m-1"> 
                    <label className="col-4">Address</label>
                    <input className="col-4 form-control" name="address" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">City</label>
                    <input className="col-4 form-control" name="city" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">State</label>
                    <input className="col-4 form-control" name="state" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">Pin Code</label>
                    <input className="col-4 form-control" name="pin" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <br></br>
                <p><b>POC Details</b></p>
                <div className="row m-1">
                    <label className="col-4">Name</label>
                    <input className="col-4 form-control" name="pocName" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">Email</label>
                    <input className="col-4 form-control" name="email" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">Contact</label>
                    <input className="col-4 form-control" name="contact" type="text" onChange={e=>onChange(e)}></input>
                </div>
                <br></br>
                <p><b>Documents</b></p>
                <div className="row m-1">
                    <label className="col-4">API Documentation</label>
                    <input className="col-4 form-control" name="document" type="file" onChange={e=>fileUpload(e)}></input>
                </div>
                <div>
                    <input className="btn btn-primary" value="submit" type="submit"></input>
                </div>
            </form> : <LoadingWidget/>}
        </>
    )

}

export default AddISP