import { CSVLink } from "react-csv";
import {useContext, useState} from "react"
import DynamicTable from "../components/Table"
import {ESIMInventoryContext} from "../../inventoryContext"
import LoadingWidget from "../components/loading"

const ViewInventoryList = () => {

    const {esimInventory} = useContext(ESIMInventoryContext);
    
    const filterInactive = esimInventory && esimInventory.length > 0 ? esimInventory.filter(item=>item["esim_state"]==="notAssigned" && item["esim_status"]==="Inactive") : []
    const filterActiveNotAssigned = esimInventory && esimInventory.length > 0 ? esimInventory.filter(item=>item["esim_state"]==="notAssigned" && item["esim_status"]==="Active") : []
    const filterActiveAndAssigned = esimInventory && esimInventory.length > 0 ? esimInventory.filter(item=>item["esim_state"]==="assigned" && item["esim_status"]==="Active") : []
    const filterNotActiveButAssigned = esimInventory && esimInventory.length > 0 ? esimInventory.filter(item=>item["esim_state"]==="assigned" && item["esim_status"]==="Inactive") : []
    

    const [filteredTableData, setFilteredTableData] = useState(filterInactive)
    const [tableHeaderSequence, setTableHeaderSequence] = useState(["iccid", "esim_state", "esim_status", "primary_operator", "primary_imsi", "primary_msisdn","fallback_operator", "fallback_imsi", "fallback_msisdn"])
    const [isLoading, setIsLoading] = useState(false)

    const onWidgetFilter = (e) => {
        e.preventDefault()
        setIsLoading(true)
        const filteredID = e.currentTarget.id
        if (filteredID === "II"){
            setFilteredTableData(filterInactive)
            setTableHeaderSequence(["iccid", "esim_state", "esim_status", "primary_operator", "primary_imsi", "primary_msisdn","fallback_operator", "fallback_imsi", "fallback_msisdn"])
        } else if (filteredID === "ANA"){
            setFilteredTableData(filterActiveNotAssigned)
            setTableHeaderSequence(["iccid", "esim_state", "esim_status", "plan", "primary_operator", "primary_imsi", "primary_msisdn", "primary_sim_activation_type", "primary_sim_activation_date", "primary_sim_expiry_date", "primary_operator_data_limit", "primary_status", "fallback_operator", "fallback_imsi", "fallback_msisdn", "fallback_activation_type", "fallback_sim_activation_date", "fallback_sim_expiry_date", "fallback_operator_data_limit", "fallback_status"])
        } else if (filteredID === "AAA"){
            setFilteredTableData(filterActiveAndAssigned)
            setTableHeaderSequence(["iccid", "esim_state", "esim_status", "plan", "primary_operator", "primary_imsi", "primary_msisdn", "primary_sim_activation_type", "primary_sim_activation_date", "primary_sim_expiry_date", "primary_operator_data_limit", "primary_status", "fallback_operator", "fallback_imsi", "fallback_msisdn", "fallback_activation_type", "fallback_sim_activation_date", "fallback_sim_expiry_date", "fallback_operator_data_limit", "fallback_status", "manufacturer", "amount"])
        } else if (filteredID === "ASNA"){
            setFilteredTableData(filterNotActiveButAssigned)
            setTableHeaderSequence(["iccid","imei", "esim_state", "esim_status", "primary_operator", "primary_imsi", "primary_msisdn", "fallback_operator", "fallback_imsi", "fallback_msisdn", "manufacturer", "amount", "ipwhitelist1", "ipwhitelist2", "ipwhitelist3", "ipwhitelist4", "smsnumber1", "smsnumber2", "smsnumber3", "smsnumber4"])
        } 
        setIsLoading(false)
    }

    const combinedDataWidget = (count, text, id) => {
        var shapeStyle = "border rounded m-1"
        return(
            <div className="mt-2 col-3 p-2" id ={id} onClick={(e)=>onWidgetFilter(e)}>
                <div className={shapeStyle}>
                    <p className="p-1 m-0 ml-1"><b>{text}</b></p>
                    <h2 className="p-1 m-0 ml-1">{count}</h2>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="row m-1">
                {combinedDataWidget(filterInactive.length, "Inactive Inventory", "II")}
                {combinedDataWidget(filterActiveAndAssigned.length, "Active And Assigned", "AAA")}
                {combinedDataWidget(filterNotActiveButAssigned.length, "Assigned Not Active", "ASNA")}
                {combinedDataWidget(filterActiveNotAssigned.length, "Active Not Assigned", "ANA")}
            </div>
            <div>
                <CSVLink data = {filteredTableData} className="btn btn-warning m-1 ml-2 p-1" filename="ESIM_Inventory">Download Report in CSV</CSVLink>
            </div>
            {!isLoading ? filteredTableData && filteredTableData.length > 0 ? <DynamicTable data = {filteredTableData} sequence = {tableHeaderSequence} filter_required = {true}/> : <p>No Inventory Detail Available for Selection</p> : <LoadingWidget />}
        </>
    )
}

export default ViewInventoryList