import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import App from './App';
import ActivateInventory from './Screens/esiminventory/activateInventory';
import AddInventory from './Screens/esiminventory/addInventory';
import AssignInventory from './Screens/esiminventory/assignInventory';
import Inventory from './Screens/esiminventory/inventory';
import InventoryUpdate from './Screens/esiminventory/inventoryUpdate';
import ViewInventoryList from './Screens/esiminventory/viewInventoryList';
import AddISP from './Screens/internetProvider/addInternetProvider';
import EditISP from './Screens/internetProvider/editISP';
import ISP from './Screens/internetProvider/internetProvider';
import ViewISPDetail from './Screens/internetProvider/viewISPDetail';
import ViewISPList from './Screens/internetProvider/viewISPList';
import AddManufacturer from './Screens/manufacturer/addManufacturer';
import EditManufacturer from './Screens/manufacturer/editManufacturer';
import Manufacturer from './Screens/manufacturer/manufacturer';
import ViewManufacturerList from './Screens/manufacturer/viewManufacturerList';
import ViewManufacturerDetail from './Screens/manufacturer/viewManufacturerDetail';
import SIMPlans from './Screens/plans/simPlans';
import AddSIMPlan from './Screens/plans/addSIMPlan';
import EditSIMPlan from './Screens/plans/editSIMPlan';
import ViewSIMPlanList from './Screens/plans/viewSIMPlansList';
import User from './Screens/users/user';
import UserList from './Screens/users/usersList';
import CreateUser from './Screens/users/createUser';
import Reports from './Screens/reports';
import {UserProvider} from "./userContext";
import {ManufacturerProvider} from "./manufacturerContext";
import {ISPProvider} from './ispContext';
import {ESIMInventoryProvider} from "./inventoryContext"
import {SIMPlanProvider} from './simPlansContext';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    children: [
      {
        path: "",
        element: <Reports />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "manufacturers",  
        element: <Manufacturer />,
        children:[
          {path: "manufacturerDetail", element: <ViewManufacturerDetail />},
          {path: "addManufacturer", element: <AddManufacturer />},
          {path: "viewManufacturerList", element: <ViewManufacturerList />},
          {path: "editManufacturer", element: <EditManufacturer />}
        ]
      },
      {
        path: "mobileOperator",
        element: <ISP />,
        children:[
          {path: "mobileOperatorDetail", element: <ViewISPDetail />},
          {path: "addMobileOperator", element: <AddISP />},
          {path: "viewOperatorList", element: <ViewISPList />},
          {path: "editOperator", element: <EditISP />}
        ]
      },
      {
        path: "simPlansMaster",
        element: <SIMPlans />,
        children:[
          {path: "addSIMPlan", element: <AddSIMPlan />},
          {path: "viewSIMList", element: <ViewSIMPlanList />},
          {path: "editSIMPlan", element: <EditSIMPlan />}
        ]
      },
      {
        path: "esimInventory",
        element: <Inventory />,
        children:[
          {path: "inventoryUpdate", element: <InventoryUpdate />},
          {path: "inventoryList", element: <ViewInventoryList />},
          {path: "addInventory", element: <AddInventory />},
          {path: "activateInventory", element: <ActivateInventory />},
          {path: "assignInventory", element: <AssignInventory />},
        ]
      },
      {
        path: "user",
        element: <User />,
        children:[
          {path: "createUser", element: <CreateUser />},
          {path: "userList", element: <UserList />},
        ]
      }
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <ManufacturerProvider>
      <ISPProvider>
        <SIMPlanProvider>
          <ESIMInventoryProvider>
            <React.StrictMode>
                <RouterProvider router={router} />
            </React.StrictMode>
          </ESIMInventoryProvider>
        </SIMPlanProvider>
      </ISPProvider>
    </ManufacturerProvider>
  </UserProvider>
);
