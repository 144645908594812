const updateESIMState = (type, data, currentValue) => {
    const types = "activation, deactivation, suspend, expired, assigned, add, update"

    if(type === "add"){
        var data_upload = {...data}    
            data_upload["updated_by"] = "admin"
            data_upload["esim_status"] = "Inactive" 
            data_upload["manufacturer"] = "na"
            data_upload["amount"] = 0
            data_upload["plan"] = "na"
            data_upload["primary_sim_activation_type"] = "na"
            data_upload["primary_operator_data_limit"] = "na"
            data_upload["primary_status"] = "Inactive"
            data_upload["primary_sim_activation_date"] = new Date(1970, 1, 1)
            data_upload["primary_sim_expiry_date"] = new Date(1970, 1, 1)
            data_upload["fallback_activation_type"] = "na"
            data_upload["fallback_operator_data_limit"] = "na"
            data_upload["fallback_status"] = "Inactive"
            data_upload["fallback_sim_activation_date"] = new Date(1970, 1, 1)
            data_upload["fallback_sim_expiry_date"] = new Date(1970, 1, 1)
            data_upload["esim_state"] = "notAssigned"
            data_upload["ipwhitelist1"] = ""
            data_upload["ipwhitelist2"] = ""
            data_upload["ipwhitelist3"] = ""
            data_upload["ipwhitelist4"] = ""

            data_upload["smsnumber1"] = ""
            data_upload["smsnumber2"] = ""
            data_upload["smsnumber3"] = ""
            data_upload["smsnumber4"] = ""

            data_upload["imei"] = ""
        return data_upload
    }
}

export default updateESIMState