const url = "https://iotsim.co.in/"

export async function addUser(data){
    
    let token = sessionStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var raw = JSON.stringify(data);    

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
    try {
        const response = await fetch(url+"api/Login/adduser", requestOptions)
        const data_response = await response.json()
        return data_response
    } catch (e) {
        return e
    }
    
}

export async function getUserList(){
    
    let token = sessionStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    
    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: myHeaders,    
      };

    try {
        const response = await fetch(url+"api/Login/getuserlist", requestOptions)
        console.log("response", response)
        const data_response = await response.json()
        return data_response
    } catch (e) {
        return e
    }
    
}
