import {useContext} from "react"
import DynamicTable from "../components/Table"
import { ISPContext } from "../../ispContext"

const ViewISPList = () => {
    const {ISP} = useContext(ISPContext);

    return (
        <>
            {ISP && ISP.length > 0 ? <DynamicTable data = {ISP} sequence = {["providerCode","name", "gst", "address", "city", "state", "pin", "pocName", "email", "contact", "document" ]} links={"/manufacturers/manufacturer_detail/"}/> : <p>No ISP created on the platform Yet!!</p>}
        </>   
    )
}

export default ViewISPList