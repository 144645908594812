import {useState, useContext} from "react"
import {getSingleESIMDetails, updateESIM } from "../../API/inventory";
import LoadingWidget from "../components/loading"
import {useNavigate} from "react-router-dom";
import {ESIMInventoryContext} from "../../inventoryContext";
import {SIMPlansContext} from "../../simPlansContext"
import DynamicTable from "../components/Table";

const InventoryUpdate = () => {

    const {updateInventory} = useContext(ESIMInventoryContext)
    const {SIMPlans} = useContext(SIMPlansContext)
    const navigate = useNavigate()

    const [data, setData] = useState({})

    const [actionType, setActionType] = useState(null)

    const [errorArray, setErrorArray] = useState([])
    
    const [singleICCIDSelected, setSingleICCIDSelected] = useState(null)
    const [selectedESIMDetail, setSelectedESIMDetail] = useState(null)

    const [filteredPrimaryPlans, setFilteredPrimaryArrays] = useState([])
    const [primarySimPlan, setPrimarySimPlan] = useState(null)

    const [filteredSecondaryPlans, setFilteredSecondaryArrays] = useState([])
    const [fallbackSimPlan, setFallbackSimPlan] = useState(null)

    const [primaryActivationDate, setPrimaryActivationDate] = useState(null)
    const [secondaryActivationDate, setSecondaryActivationDate] = useState(null)

    const [isLoading, setIsLoading] = useState(false)


    let primarySIMSelectedPlan = primarySimPlan ? SIMPlans.filter(item=>item["planTag"]===primarySimPlan)[0] : null
    let secondarySIMSelectedPlan = fallbackSimPlan ? SIMPlans.filter(item=>item["planTag"]===fallbackSimPlan)[0] : null

    const handleFetch = async (e) => {
        e.preventDefault();
        var response = await getSingleESIMDetails(singleICCIDSelected)
        if (!response["status"]){
            let modified_error_array = errorArray
            modified_error_array.push(response["message"])
            setErrorArray(modified_error_array)
        } else {
            setSelectedESIMDetail(response["inventory"])
            
            const filterPrimaryArray = SIMPlans.filter(item=> {
                            if(actionType==="extendExpiry"){
                                return response["inventory"]["primary_operator"] === item["planTag"].split("_")[0] && (item["planDescription"] === "TOPUP" || item["planDescription"] === "RECHARGE")
                            } else if(actionType==="extendExpiry"){
                                return response["inventory"]["primary_operator"] === item["planTag"].split("_")[0] && (item["planDescription"] === "BOOTSTRAP" || item["planDescription"] === "COMMERCIAL")
                            } 
                        })
            setFilteredPrimaryArrays(filterPrimaryArray)
            
            const filterSecondaryArray = SIMPlans.filter(item=> {
                if(actionType==="extendExpiry"){
                    return response["inventory"]["fallback_operator"] === item["planTag"].split("_")[0] && (item["planDescription"] === "TOPUP" || item["planDescription"] === "RECHARGE")
                } else if(actionType==="extendExpiry"){
                    return response["inventory"]["fallback_operator"] === item["planTag"].split("_")[0] && (item["planDescription"] === "BOOTSTRAP" || item["planDescription"] === "COMMERCIAL")
                } 
            })
            setFilteredSecondaryArrays(filterSecondaryArray)
        }
    }

    const onChange = (e) => {
        const data_new = data
        data_new[e.target.name] = e.target.value
        setData(data_new)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        var data_upload = selectedESIMDetail
        Object.keys(data).forEach(item=> data_upload[item]=data[item])        
    }

    
    const getExpiryDate = (date, months) => {
        const monthToDayMap = {1:30, 2:60, 3:90, 6:180, 12: 365, 24: 730}
        let result = new Date(date)
        result.setDate(result.getDate() + monthToDayMap[months]);
        return result.toDateString()
    }

    return (
        <>
        {!isLoading ? <div>
        <br></br>
            <hr></hr>
            <div className="row m-1">
                    <label className="col-4">Select ESIM Operations</label>
                    <select className="col-4 form-control" name="actionType" value = {actionType} onChange = {e=>setActionType(e.target.value)} >
                        <option value = "extendExpiry">Recharge or TopUp</option>
                        <option value = "suspend">Suspend</option>
                        <option value = "update">Plan Change</option>
                    </select>
            </div>

            {errorArray.map(item=><p className = "alert alert-danger">{item}</p>)}                
 
            <div>
            <form className = "m-2">
                <div className="row m-1">
                    <label className="col-4">ICCID Number</label>
                    <input className="col-4 form-control" name="iccid" type="text" onChange={e=>setSingleICCIDSelected(e.target.value)}></input>
                    <button className="btn btn-primary ml-2" onClick={e=>handleFetch(e)}>Fetch</button>
                </div> 
            </form>
            {selectedESIMDetail ? 
            <div>
                <p><b>Selected ESIM</b></p>
                <DynamicTable data = {[selectedESIMDetail]} sequence = {["iccid", "primary_operator", "primary_imsi", "primary_msisdn", "fallback_operator", "fallback_imsi", "fallback_msisdn", "manufacturer"]} links={"/manufacturers/manufacturer_detail/"}/>
                
                {selectedESIMDetail["esim_status"] == "Active" ? <div>
                    <DynamicTable data = {[selectedESIMDetail]} sequence = {['plan', 'primary_sim_activation_type', 'primary_sim_activation_date', 'primary_sim_expiry_date', 'primary_operator_data_limit', 'primary_status', 'fallback_activation_type', 'fallback_sim_activation_date', 'fallback_sim_expiry_date', 'fallback_operator_data_limit', 'fallback_status']} links={"/manufacturers/manufacturer_detail/"}/>
                </div> :  null}
                                
                <div className="row m-1">
                    <label className="col-4">Primary Plan</label>
                    <select required className="col-4 form-control" name="primary_sim_plan" value = {primarySimPlan} onChange = {e=>setPrimarySimPlan(e.target.value)} >
                        <option value = "">Select</option>
                        {filteredPrimaryPlans.length > 0 ? filteredPrimaryPlans.map(item=>{
                            return <option value = {item["planTag"]}>{item["planTag"]}</option>    
                        }): null}
                    </select>
                </div> 
                <div className="row m-1">
                    <label className="col-4">Primary Activation Type</label>
                    <p className="col-4">{primarySimPlan && primarySIMSelectedPlan ? primarySIMSelectedPlan["planDescription"] : "Select a Primary Plan"}</p>
                </div>
                <div className="row m-1">
                    <label className="col-4">Primary Data Limit</label>
                    <p className="col-4">{primarySimPlan && primarySIMSelectedPlan ? primarySIMSelectedPlan["dataLimitMB"] + " MB" : "Select a Primary Plan"}</p>
                </div>
                <div className="row m-1">
                    <label className="col-4">Primary SMS Limit</label>
                    <p className="col-4">{primarySimPlan && primarySIMSelectedPlan ? primarySIMSelectedPlan["smsLimitCount"] + " SMS" : "Select a Primary Plan"}</p>
                </div>
                <div className="row m-1">
                    <label className="col-4">Primary Activation Date</label>
                    <input required className="col-4 form-control" name="primary_sim_activation_date" type="date" onChange={e=>setPrimaryActivationDate(e.target.value)}></input>
                </div>
                <div className="row m-1">
                    <label className="col-4">Primary Expiry Date</label>
                    <p className="col-4">{primarySimPlan &&  primaryActivationDate ? getExpiryDate(primaryActivationDate, primarySIMSelectedPlan["planValidityMonth"]) : "Select a Primary Plan / Activation Date"}</p>
                </div>
                
                <div className="row m-1">
                    <label className="col-4">Secondary Plan</label>
                    <select required className="col-4 form-control" name="fallback_sim_plan" value = {fallbackSimPlan} onChange = {e=>setFallbackSimPlan(e.target.value)} >
                        <option value = "">Select</option>
                        {filteredSecondaryPlans.length > 0 ? filteredSecondaryPlans.map(item=>{
                            return <option value = {item["planTag"]}>{item["planTag"]}</option>    
                        }): null}
                    </select>
                </div> 
                <div className="row m-1">
                    <label className="col-4">Fallback Activation Type</label>
                    <p className="col-4">{fallbackSimPlan && secondarySIMSelectedPlan ? secondarySIMSelectedPlan["planDescription"] : "Select a Fallback Plan"}</p>
                </div>
                <div className="row m-1">
                    <label className="col-4">Fallback Data Limit</label>
                    <p className="col-4">{fallbackSimPlan && secondarySIMSelectedPlan ? secondarySIMSelectedPlan["dataLimitMB"] + " MB" : "Select a Fallback Plan"}</p>
                </div>
                <div className="row m-1">
                    <label className="col-4">Fallback SMS Limit</label>
                    <p className="col-4">{fallbackSimPlan && secondarySIMSelectedPlan ? secondarySIMSelectedPlan["smsLimitCount"] + " SMS" : "Select a Fallback Plan"}</p>
                </div>
                <div className="row m-1">
                    <label className="col-4">Fallback Activation Date</label>
                    <input className="col-4 form-control" name="fallback_sim_activation_date" type="date" onChange={e=>setSecondaryActivationDate(e.target.value)}></input> 
                </div>
                <div className="row m-1">
                    <label className="col-4">Fallback Expiry Date</label>
                    <p className="col-4">{fallbackSimPlan &&  secondaryActivationDate ? getExpiryDate(secondaryActivationDate, secondarySIMSelectedPlan["planValidityMonth"]) : "Select a Secondary Plan / Activation Date"}</p>
                </div> 
                <button className='btn btn-primary m-1' onClick={e=>handleSubmit(e)}>Save</button>              
            </div> 
            :null}    
        </div>
            
        </div> : <LoadingWidget/>}
    </>
    )

}

export default InventoryUpdate