import { CSVLink } from "react-csv";
import {useState, useEffect, useContext} from 'react';
import { ManufacturerContext } from "../manufacturerContext";
import { ISPContext } from "../ispContext";
import {ESIMInventoryContext} from "../inventoryContext"
///import JsonToTable from "./components/JsonToTable"
import DynamicTable from "./components/Table"

const Reports = () => {
    
    const [PrimaryISPFilter, setPrimaryISPFilter] = useState("")
    const [FallbackISPFilter, setFallbackISPFilter] = useState("")    
    const [ManufacturerFilter, setManufacturerFilter] = useState("")
    const [ESIMState, setESIMState] = useState("")
    const [ESIMStatus, setESIMStatus] = useState("")
    const [filteredESIMs, setFilteredESIMs] = useState([])    

    const {manufacturers} = useContext(ManufacturerContext)
    const {ISP} = useContext(ISPContext)
    const {esimInventory} = useContext(ESIMInventoryContext)

    useEffect(()=>{
        if(esimInventory && esimInventory.length > 0){
            setFilteredESIMs(esimInventory)
        }
    },[esimInventory])


    useEffect(()=>{
        let filteredData = esimInventory
        filteredData = PrimaryISPFilter !== "" ? filteredData.filter(item=> item["primary_operator"] === PrimaryISPFilter) : filteredData
        filteredData = FallbackISPFilter !== "" ? filteredData.filter(item=> item["fallback_operator"] === FallbackISPFilter) : filteredData
        filteredData = ManufacturerFilter !== "" ? filteredData.filter(item=> item["manufacturer"] === ManufacturerFilter) : filteredData
        filteredData = ESIMState !== "" ? filteredData.filter(item=> item["esim_state"] === ESIMState) : filteredData
        filteredData = ESIMStatus !== "" ? filteredData.filter(item=> item["esim_status"] === ESIMStatus) : filteredData
        setFilteredESIMs(filteredData)
    },[PrimaryISPFilter, FallbackISPFilter, ManufacturerFilter, ESIMState, ESIMStatus])

    return (
        <div>
            <br></br>
            <p><b>Reports</b></p>
            <hr></hr>
            <div className="row m-1">
                <div className="col-2"> 
                    <div><label>ESIM State</label></div>
                    <select className='w-100' name="esimState" value = {ESIMState} onChange = {e=>setESIMState(e.target.value)} >
                        <option value = "">Select</option>
                        <option value = "notActivated">Not Activated</option>
                        <option value = "activated">Activated</option>
                    </select>
                </div>
                <div className="col-2"> 
                    <div><label>ESIM Assignment</label></div>
                    <select className='w-100' name="esimState" value = {ESIMStatus} onChange = {e=>setESIMStatus(e.target.value)} >
                        <option value = "">Select</option>
                        <option value = "notAssigned">Not Assigned</option>
                        <option value = "assigned">Assigned</option>
                    </select>
                </div>
                {ISP && ISP.length > 0 ? <div className="col-2"> 
                    <div><label>ISP Primary</label></div>
                    <select className='w-100' name="primaryISP" value = {PrimaryISPFilter} onChange = {e=>setPrimaryISPFilter(e.target.value)} >
                        <option value = "">Select</option>
                        {ISP.map(item=><option value = {item["providerCode"]}>{item["providerCode"]}</option>)}
                    </select>
                </div> : <p>... Loading</p>}
                {ISP && ISP.length > 0 ? <div className="col-2"> 
                    <div><label>ISP Fallback</label></div>
                    <select className='w-100' name="primaryISP" value = {FallbackISPFilter} onChange = {e=>setFallbackISPFilter(e.target.value)} >
                        <option value = "">Select</option>
                        {ISP.map(item=><option value = {item["providerCode"]}>{item["providerCode"]}</option>)}
                    </select>
                </div> : <p>... Loading</p>}
                {manufacturers && manufacturers.length > 0 ? <div className="col-2"> 
                    <div><label>Manufacturer</label></div>
                    <select className='w-100' name="primaryISP" value = {ManufacturerFilter} onChange = {e=>setManufacturerFilter(e.target.value)} >
                        <option value = "">Select</option>
                        {manufacturers.map(item=><option value = {item["code"]}>{item["code"]}</option>)}
                    </select>
                </div> : <p>... Loading</p>}
                <div className="col-2 align-bottom">
                    <p className="mb-0">Report</p>
                    {filteredESIMs && filteredESIMs.length > 0 ? <CSVLink data = {filteredESIMs} className="btn btn-warning btn-sm" filename="ESIM_Inventory_report">Download</CSVLink>: null}
                </div>
            </div>
            {filteredESIMs && filteredESIMs.length > 0 ? <DynamicTable data = {filteredESIMs} sequence = {["iccid", "primary_operator", "primary_imsi", "primary_msisdn", "primary_sim_activation_type", "primary_sim_activation_date", "primary_sim_expiry_date", "primary_operator_data_limit", "primary_status", "fallback_operator", "fallback_imsi", "fallback_msisdn", "fallback_activation_type", "fallback_sim_activation_date", "fallback_sim_expiry_date", "fallback_operator_data_limit", "fallback_status", "esim_state", "esim_status", "plan", "manufacturer", "amount", "updated_by"]} filter_required = {true}/> : <p>...Loading</p>}
        </div>
    )
}

export default Reports