const url = "https://iotsim.co.in/"

export async function getESIMInventory(){
    
    let data = sessionStorage.getItem("token");

    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + data);

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders,
      };
      
    let response = await fetch(url+"api/Inventory/getinventory", requestOptions)
    return response.json()
}

export async function addESIMInventory(data){
    let token = sessionStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);
    
    data["amount"] = parseInt(data["amount"])
    data["iccid"] = String(data["iccid"])
    data["primary_imsi"] = String(data["primary_imsi"])
    data["primary_msisdn"] = String(data["primary_msisdn"])
    data["fallback_imsi"] = String(data["fallback_imsi"])
    data["fallback_msisdn"] = String(data["fallback_msisdn"])
    data["primary_operator_data_limit"] = String(data["primary_operator_data_limit"])
    data["fallback_operator_data_limit"] = String(data["fallback_operator_data_limit"])

    var raw = JSON.stringify(data);    
    console.log("raw", raw)

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
    try {
        const response = await fetch(url+"api/Inventory/addinventory", requestOptions)
        const data_response = await response.json()
        return data_response
    } catch (e) {
        return e
    }    
}

export async function getSingleESIMDetails(data){
    let token = sessionStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", "Bearer " + token);
    
    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };
    try {
        const response = await fetch(url+"api/Inventory/getinventorybyiccid?iccid="+data, requestOptions)
        const data_response = await response.json()
        return data_response
    } catch (e) {
        return e
    }    

}


export async function activateESIM(data){
    let token = sessionStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);
    
    data["amount"] = parseInt(data["amount"])
    data["iccid"] = String(data["iccid"])
    data["primary_imsi"] = String(data["primary_imsi"])
    data["primary_msisdn"] = String(data["primary_msisdn"])
    data["fallback_imsi"] = String(data["fallback_imsi"])
    data["fallback_msisdn"] = String(data["fallback_msisdn"])
    data["primary_operator_data_limit"] = String(data["primary_operator_data_limit"])
    data["fallback_operator_data_limit"] = String(data["fallback_operator_data_limit"])
    
    data["primary_sim_activation_date"] = new Date(data["primary_sim_activation_date"])
    data["primary_sim_expiry_date"] = new Date(data["primary_sim_expiry_date"])
    data["fallback_sim_activation_date"] = new Date(data["fallback_sim_activation_date"])
    data["fallback_sim_expiry_date"] = new Date(data["fallback_sim_expiry_date"])
    

    var raw = JSON.stringify(data);    

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
    try {
        const response = await fetch(url+"api/Inventory/updinventory", requestOptions)
        const data_response = await response.json()
        return data_response
    } catch (e) {
        return e
    }
}


export async function assignESIM(data){
    let token = sessionStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);
    
    data["amount"] = parseInt(data["amount"])
    data["iccid"] = String(data["iccid"])
    data["primary_imsi"] = String(data["primary_imsi"])
    data["primary_msisdn"] = String(data["primary_msisdn"])
    data["fallback_imsi"] = String(data["fallback_imsi"])
    data["fallback_msisdn"] = String(data["fallback_msisdn"])
    data["primary_operator_data_limit"] = String(data["primary_operator_data_limit"])
    data["fallback_operator_data_limit"] = String(data["fallback_operator_data_limit"])
    
    var raw = JSON.stringify(data);    

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
    try {
        const response = await fetch(url+"api/Inventory/updinventory", requestOptions)
        const data_response = await response.json()
        return data_response
    } catch (e) {
        return e
    }
}

export async function updateESIM(data){
    let token = sessionStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);
    
    data["amount"] = parseInt(data["amount"])
    
    var raw = JSON.stringify(data);    

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
    try {
        const response = await fetch(url+"api/Inventory/updinventory", requestOptions)
        const data_response = await response.json()
        return data_response
    } catch (e) {
        return e
    }
}